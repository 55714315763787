import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Cambiar Switch por Routes
import './App.css';
import Header from './components/Header';
import PlacesList from './components/PlacesList';
import PlaceInfo from './components/PlaceInfo'; // Asegúrate de que esta ruta sea correcta
import placesData from './places.json'; // Asegúrate de que la ruta sea correcta

const App = () => {
  // Estado para los lugares
  const [places, setPlaces] = useState([]);

  useEffect(() => {
    // Carga los lugares desde el archivo JSON
    setPlaces(placesData);
  }, []); // Se ejecuta solo una vez al montar el componente

  return (
    <Router>
      <Header setPlaces={setPlaces} />
      <Routes> {/* Cambiado de Switch a Routes */}
        <Route path="/" element={<PlacesList places={places} />} /> {/* Renderiza la lista de lugares */}
        <Route path="/place/:id" element={<PlaceInfo />} /> {/* Ruta para la info del salón */}
      </Routes>
    </Router>
  );
};

export default App;
