import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import placesData from '../places.json';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { es } from 'date-fns/locale';
import moment from 'moment';
import 'moment/locale/es';
import './PlaceInfo.css';
import { FaWhatsapp } from 'react-icons/fa';

const PlaceInfo = () => {
  const { id } = useParams();
  const place = placesData.find((place) => place.id === parseInt(id));
  const [selectedDate, setSelectedDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [people, setPeople] = useState(1);
  const [dateSelected, setDateSelected] = useState(false);
  const datePickerRef = useRef(null);

  if (!place) {
    return <div>No se encontró el salón.</div>;
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setDateSelected(true);
  };

  const handleWhatsAppClickMobile = () => {
    if (!dateSelected) {
      datePickerRef.current.setOpen(true); // Abre el calendario si no se ha seleccionado una fecha
    } else {
      openWhatsApp();
    }
  };

  const handleWhatsAppClickPC = () => {
    if (!dateSelected) {
      datePickerRef.current.setOpen(true); // Abre el calendario si no se ha seleccionado una fecha
    } else {
      openWhatsApp();
    }
  };

  const openWhatsApp = () => {
    const formattedDate = moment(selectedDate).locale('es').format('dddd D [de] MMMM [de] YYYY');
    const message = `Hola, vi su anuncio en Eventotal.net, sobre ${place.name}, me interesa para el ${formattedDate}, para ${people} personas. ¿Está disponible?`;
    const whatsappLink = `https://api.whatsapp.com/send?phone=${place.whatsApp}&text=${encodeURIComponent(message)}`;
    window.open(whatsappLink, '_blank');
  };

  const handlePeopleChange = (e) => {
    const value = Math.max(1, e.target.value); // Asegura que el valor no sea menor a 1
    setPeople(value);
  };

  return (
    <div className="place-info-container">
      <h1 className="place-title">{place.name}</h1>
      
      <div className="place-gallery">
        <img 
          className="place-cover-image" 
          src={place.coverImage} 
          alt={place.name} 
          onClick={() => { setPhotoIndex(0); setIsOpen(true); }} 
        />
        <div className="place-images">
          {place.galleryImages.map((image, index) => (
            <img 
              key={index} 
              className="gallery-image" 
              src={image} 
              alt={`Gallery ${index + 1}`} 
              onClick={() => { setPhotoIndex(index + 1); setIsOpen(true); }}
            />
          ))}
        </div>
      </div>

      <div className="place-details-whatsapp">
        <div className="place-details">
          <h2>{place.location}</h2>
          <p className="place-description">{place.description}</p>
          <h3>Lo que ofrece este lugar:</h3>
          <ul>
            {place.amenities.map((amenity, index) => (
              <li key={index}>{amenity}</li>
            ))}
          </ul>
        </div>

        {/* Botón de WhatsApp para reservar */}
        <div className="whatsapp-section booking-card">
          <p className="price">{place.price} MXN por evento</p>
          <DatePicker
            ref={datePickerRef}
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="yyyy-MM-dd"
            className="date-picker"
            placeholderText="Selecciona fecha de tu evento"
            minDate={new Date()}
            locale={es}
          />
          <div className="people-input-container">
            <input 
              type="number" 
              className="people-input" 
              value={people} 
              onChange={handlePeopleChange} 
              min="1" 
            />
            <span className="people-label">Invitados</span>
          </div>
          <button className="whatsapp-button" onClick={handleWhatsAppClickPC}>
            <FaWhatsapp className="whatsapp-icon" /> {dateSelected ? 'Reservar' : 'Seleccionar fecha'}
          </button>
          <p className="no-charge-message">Aún no se te cobrará nada.</p>
        </div>
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={place.galleryImages[photoIndex - 1] || place.coverImage}
          nextSrc={place.galleryImages[(photoIndex) % place.galleryImages.length]}
          prevSrc={place.galleryImages[(photoIndex + place.galleryImages.length - 2) % place.galleryImages.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + place.galleryImages.length - 1) % place.galleryImages.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % place.galleryImages.length)}
        />
      )}

      {/* Tarjeta sticky con nueva disposición */}
      <div className="sticky-bottom-card">
        <div className="sticky-info">
          <div className="sticky-left">
            <DatePicker
              ref={datePickerRef}
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="yyyy-MM-dd"
              className="date-picker"
              placeholderText="Seleccionar fecha"
              minDate={new Date()}
              locale={es}
            />
            <div className="people-input-container sticky-people-input">
              <input 
                type="number" 
                className="people-input" 
                value={people} 
                onChange={handlePeopleChange} 
                min="1" 
              />
              <span className="people-label">Invitados</span>
            </div>
          </div>
          <div className="sticky-right">
            <p className="price-sticky">{place.price} MXN</p>
            <button className="sticky-reserve-button" onClick={handleWhatsAppClickMobile}>
              Reservar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaceInfo;
