import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/eventotal-high-resolution-logo-transparent.png'; // Ajustar la ruta
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <div className="header__left">
        <Link to="/"> {/* Envolver en Link para redirigir a la página principal */}
          <img 
            src={logo} // Usar la imagen importada
            alt="logo" 
            className="header__logo"
          />
        </Link>
      </div>

      {/* Eliminar la barra de búsqueda */}
      {/* Aquí puedes añadir otros elementos del header si lo deseas */}

      <div className="header__right">
        <p>Pon tu salón en Eventotal</p>
        <img 
          src="https://image.shutterstock.com/image-vector/user-avatar-icon-sign-profile-260nw-1145752283.jpg" 
          alt="user" 
          className="header__avatar"
        />
        <div className="header__menuIcon">☰</div>
      </div>
    </header>
  );
};

export default Header;
