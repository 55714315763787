import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import './PlacesList.css';

const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5); // Mezcla el array de forma aleatoria
};

const PlacesList = ({ places }) => {
  const [displayedPlaces, setDisplayedPlaces] = useState([]); // Estado para los lugares mostrados
  const [hasMore, setHasMore] = useState(true); // Estado para saber si hay más elementos para cargar
  const [page, setPage] = useState(0); // Controlar la página actual
  const [shuffledPlaces, setShuffledPlaces] = useState([]); // Estado para los lugares mezclados

  useEffect(() => {
    // Mezclar los lugares una vez cuando el componente se monta
    const randomPlaces = shuffleArray([...places]);
    setShuffledPlaces(randomPlaces); // Guardar los lugares mezclados
    setDisplayedPlaces(randomPlaces.slice(0, 20)); // Cargar los primeros 20 elementos
    setHasMore(randomPlaces.length > 20); // Verificar si hay más elementos
  }, [places]); // Dependencia solo en 'places'

  const loadMorePlaces = () => {
    const nextPage = page + 1;
    const newPlaces = shuffledPlaces.slice(nextPage * 20, (nextPage + 1) * 20); // Cargar los siguientes 20 elementos

    if (newPlaces.length > 0) {
      setDisplayedPlaces(prev => [...prev, ...newPlaces]); // Agregar los nuevos elementos al estado
      setPage(nextPage); // Incrementar la página
    }

    // Verificar si ya se han mostrado todos los elementos
    if ((nextPage + 1) * 20 >= shuffledPlaces.length) {
      setHasMore(false); // Si ya no hay más, establecer hasMore en false
    }
  };

  return (
    <InfiniteScroll
      dataLength={displayedPlaces.length} // Número de elementos actuales
      next={loadMorePlaces} // Función para cargar más elementos
      hasMore={hasMore} // Si hay más elementos
      loader={<h4>Loading...</h4>} // Cargador mientras se obtienen más elementos
      endMessage={<p style={{ textAlign: 'center' }}><b>No hay más lugares por ahora...</b></p>} // Mensaje al final
    >
      <div className="places-list">
        {displayedPlaces.map(place => (
          <Link to={`/place/${place.id}`} className="place-card" key={place.id}> {/* Envolver en Link */}
            <img className="place-image" src={place.coverImage} alt={place.name} />
            <div className="place-info">
              <h3>{place.name}</h3>
              <p>{place.location}</p>
              <p>{place.price}</p>
            </div>
          </Link>
        ))}
      </div>
    </InfiniteScroll>
  );
};

export default PlacesList;
